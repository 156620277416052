<template>
<div>
    <section id="knowledge-base-content">
        <b-row class="kb-search-content-info match-height">
            <b-col md="2" sm="6" class="kb-search-content" v-for="element in modules" :key="element.id">
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <h2 class="mb-25 font-weight-bolder">
                                <span class="text-primary">{{element.checkLimit}}</span>/<span class="text-danger">{{element.limit!=-1?element.limit:'~'}}</span>
                            </h2>
                            <span>{{element.mdCode}}</span>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </section>
</div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BProgress
} from 'bootstrap-vue'
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BButton,
        BCardText,
        BProgress
    },
    data() {
        return {
            modules: [],
        }
    },
    async beforeCreate() {
        var modules = await this.$http.get("Company/CheckModule");
        this.modules = modules.data.data;
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
