<template>
<div>

    <b-card class="card-congratulation-medal text-center">
        <h5>Hoş Geldin 🎉 {{firstName +' '+lastName}} !</h5>
        <b-img :src="require('@/assets/images/illustration/badge.svg')" class="congratulation-medal" style="z-index:2" alt="Medal Pic" />
    </b-card>

    <module-limit v-if="userType=='COMPANYADMIN'" />
    <api-heatly v-if="userType=='SUPERADMIN'" />
    <calendar v-if="userType=='TEACHER'"/>
</div>
</template>

<script>
import { BCard, BCardText, BLink,BImg } from 'bootstrap-vue'
import ApiHeatly from './Dashboard/ApiHeatly.vue'
import StatisticsCardLine from '@core/components/statistics-cards/StatisticCardVertical.vue'
import ModuleLimit from './Dashboard/ModuleLimit.vue'
import Calendar from "./Apps/Calendar.vue"
export default {
    components: {
        BCard,
        BCardText,
        BLink,
        ApiHeatly,
        ModuleLimit,
        StatisticsCardLine,
        BImg,
        Calendar
    },
    setup() {
        const { firstName, lastName, userType } = JSON.parse(localStorage.getItem("userData"));
  
       
        return {
            firstName,
            lastName,
            userType
        }
    },
}
</script>
