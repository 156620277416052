<template>
<div>
    <b-card no-body>
        <b-card-header>
            <b-card-title>Api Heatly</b-card-title>
            <feather-icon icon="SettingsIcon" size="18" class="text-muted cursor-pointer" />
        </b-card-header>

        <b-card-body class="pb-0">
            <!-- apex chart -->
            <vue-apex-charts ref="chart" type="line" height="320" :options="chartOptions" :series="series" />
        </b-card-body>
    </b-card>
    <b-card no-body>
        <b-card-body class="pb-0">
            <table class="table b-table" v-if="heatlyData">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Service</th>
                        <th scope="col">Status</th>
                        <th scope="col">Time</th>
                    </tr>
                </thead>
                <tr v-for="(item,index) in heatlyData" :key="item.key">
                    <td><span class="font-weight-bolder mb-12">#{{index+1 }}</span></td>
                    <td> <span class="font-weight-bolder mb-12">{{item.key}}</span></td>
                    <td> <span class="font-weight-bolder mb-12">{{item.status}}</span></td>
                    <td> <span class="font-weight-bolder mb-12">{{item.time}}</span></td>
                </tr>
            </table>
        </b-card-body>
    </b-card>
</div>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
export default {
    components: {
        VueApexCharts,
        BCardText,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    id: 'area-datetime',
                    type: 'area',
                    height: 350,
                    zoom: {
                        autoScaleYaxis: true
                    }
                },
                annotations: {
                    yaxis: [{
                        y: 1.0000000,
                        borderColor: 'red',
                        label: {
                            show: true,
                            text: 'Error',
                            style: {
                                color: "#fff",
                                background: '#00E396'
                            }
                        }
                    }],
                    xaxis: [{
                        x: new Date().getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        label: {
                            show: true,
                            text: 'Başlama Anı',
                            style: {
                                color: "#fff",
                                background: '#775DD0'
                            }
                        }
                    }]
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                xaxis: {
                    type: 'datetime',
                    min: new Date().getTime(),
                    tickAmount: 6,
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy hh:MM:ss'
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100]
                    }
                },
            },
            heatlyData: [],
            interval: {}
        }
    },
    methods: {
        getHeatly() {
            this.$http.get('/health').then(res => {
                //console.log(res.data.entries);
                var dt = Date.now();
                for (var name in res.data.entries) {
                    var serie = this.series.filter(x => x.name == name)[0];
                    var apiData = this.getTimeFormat(res.data.entries[name].duration);
                    if (serie == null) {
                        this.series.push({
                            name: name,
                            data: [
                                [dt, apiData]
                            ]
                        });
                        this.heatlyData.push({
                            key: name,
                            status: res.data.entries[name].description,
                            time: res.data.entries[name].duration
                        });
                    } else {
                        serie.data.push([dt, apiData]);
                        var heath = this.heatlyData.filter(x => x.key == name)[0];
                        heath.status = res.data.entries[name].description;
                        heath.time = res.data.entries[name].duration;
                    }
                    this.$refs.chart.updateSeries(this.series);
                }
            })
        },
        getTimeFormat(time) {
            var split = time.split(':');
            var seconds = split[split.length - 1];
            return seconds;
        }
    },
    created() {
        this.getHeatly();
        var scoped = this;
        this.interval = setInterval(function () {
            scoped.getHeatly();
        }, 10000);
    },
    destroyed() {
        clearInterval(this.interval);
    }
}
</script>
