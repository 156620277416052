<template>
<div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
                <div class="card-body pb-0">
                    <full-calendar ref="calendarRef" :options="calendarOptions" class="full-calendar" />
                </div>
            </div>
        </div>
    </div>
    <b-modal title="Detaylar" ok-title="Kapat" size="l" hide-footer ok-only centered cancel-variant="outline-secondary" no-close-on-backdrop v-model="openEdit">
        <span>Sınıf : <h1>{{details.class}}</h1></span>
        <span>Ders : <h1>{{details.lesson}}</h1></span>
        <span>Başlama Saati : <h1>{{details.startTime}}</h1></span>
        <span>Bitiş Saati : <h1>{{details.endTime}}</h1></span>
    </b-modal>
</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BProgress,
    BOverlay,
    BSidebar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
export default {
    components: {
        flatPickr,
        FullCalendar,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCardText,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BProgress,
        BOverlay,
        vSelect,
        BSidebar
    },
    data() {
        return {
            calendar: {},
            loading: false,
            openEdit: false,
            oldData: [],
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
                initialView: 'listMonth',
                headerToolbar: {
                    start: 'sidebarToggle, prev,next, title',
                    end: 'listMonth',
                },
                editable: false,
                eventResizableFromStart: false,
                eventClick: this.handleEvent,
                eventClassNames: this.eventClassName,
                dragScroll: false,
                dayMaxEvents: 3,
                locale: 'tr',
                navLinks: true,
                rerenderDelay: 350,
                events: []
            },
            details: {
                class: '',
                lesson: '',
                startTime: '',
                endTime: ''
            }
        }
    },
    created() {
        this.getSyllabusData();
    },
    methods: {
        async getSyllabusData() {
            this.loading = true;
            this.calendarOptions.events = [];
            var response = await this.$http.get("Teacher/Syllabus");
            var api = this.$refs.calendarRef.getApi();
            this.clearAllEvents();
            this.oldData = response.data.data;
            response.data.data.forEach(element => {
                api.addEvent({
                    id: element.id,
                    title: element.lesson.name,
                    date: moment(element.date, "DD-MM-YYYY").format('YYYY-MM-DD') + 'T' + element.startTime,
                    end: moment(element.date, "DD-MM-YYYY").format('YYYY-MM-DD') + 'T' + element.endTime,
                    create: true
                });
            });

            var homeworkResponse = await this.$http.get("Teacher/HomeWorks");
            homeworkResponse.data.data.forEach(element => {
                api.addEvent({
                    id: element.id,
                    title: element.name,
                    date: moment(element.startDate, "DD-MM-YYYY").format('YYYY-MM-DD') + 'T' + moment(element.startDate, "DD-MM-YYYY").format('hh:mm:ss'),
                    end: moment(element.endDate, "DD-MM-YYYY").format('YYYY-MM-DD') + 'T' + moment(element.endDate, "DD-MM-YYYY").format('hh:mm:ss'),
                    create: true
                });
            });
            api.refetchEvents();
            api.render();
            this.loading = false;
        },
        clearAllEvents() {
            var api = this.$refs.calendarRef.getApi();
            var eventSource = api.getEvents();
            eventSource.forEach(event => {
                event.remove();
            });
        },
        eventClassName: function (arg) {

            return [
                // Background Color
                `bg-light-primary`,
            ]

        },
        handleEvent: function (arg) {
            var event = arg.event;
            var data = this.oldData.filter(x => x.id == event.id);
            if (data.length > 0)
                data = data[0];
            this.details.class = data.class.name;
            this.details.lesson = data.lesson.name;
            this.details.startTime = data.startTime;
            this.details.endTime = data.endTime;
            this.openEdit = true;
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
